.App {
}

.App-logo {
  animation: spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.loader:empty {
  width: 10em;
  height: 10em;
  margin: 60px auto;
  border: 0.6em solid rgba(0, 0, 0, 0.2);
  border-left: 0.6em solid #111111;
  border-radius: 50%;
  animation: spin 1.1s infinite linear;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.card .card-content {
  max-height: 14em;
  min-height: 14em;
  overflow: hidden;
}